<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部添加优惠券一行 -->
      <!--      <div class="head">
        <div class="left">
          <b class="left_tit">添加banner</b>
        </div>
        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div> -->
      <!-- 添加广告文字一行 -->
      <!--   <div class="screen">
        <div class="screen_left">
          <i class="el-icon-search"></i>
          添加广告
        </div>
      </div> -->

      <!-- 添加时的表单主题部分 -->
      <div class="center">
        <div style="margin-left: -65%; margin-bottom: 10px">
          运营>添加banner
        </div>
        <div class="bck">
          <el-form ref="ruleForm" label-width="100px">
            <el-form-item label="适用商户:" v-show="merchantShow">
              <el-select
                v-model="business_id"
                placeholder="请选择商户"
                @change="changeMerchant(business_id)"
                style="width: 300px"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.business_id"
                  :label="item.business_name"
                  :value="item.business_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!--        <el-form-item label="适用门店:" v-show="storeShow">
            <el-select
              v-model="storeid"
              placeholder="请选择门店"
              style="width: 300px"
            >
              <el-option
                v-for="item in storeList"
                :key="item.storeid"
                :label="item.s_name"
                :value="item.storeid"
              >
              </el-option>
            </el-select>
          </el-form-item> -->

            <el-form-item label="banner名称：" prop="name">
              <el-input v-model="advert_name" style="width: 300px"></el-input>
            </el-form-item>

            <!--    <el-form-item label="广告位置：" prop="name">
            <el-select
              v-model="advert_position"
              placeholder="请选择"
              style="width: 300px"
              @change="niuceshi()"
            >
              <el-option
                v-for="item in advert_position_list"
                :key="item.key"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item> -->

            <!--   <el-form-item label="开始时间：" prop="resource">
            <el-date-picker
              style="width: 300px"
              v-model="start_time"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择开始日期"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="到期时间：" prop="resource">
            <el-date-picker
              style="width: 300px"
              v-model="end_time"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择开始日期"
            >
            </el-date-picker>
          </el-form-item> -->

            <!--   <el-form-item label="上线/下线：" prop="resource">
            <el-radio-group v-model="is_online">
              <el-radio label="1">上线</el-radio>
              <el-radio label="2">下线</el-radio>
            </el-radio-group>
          </el-form-item> -->

            <el-form-item label="banner图片：" prop="name">
              <!-- <el-button>选择上传文件</el-button> -->
              <el-upload
                class="avatar-uploader"
                :action="uploadingUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，且不超过500kb，建议图片大小350*200px
                </div>
              </el-upload>
            </el-form-item>

            <!--    <el-form-item label="广告链接：" prop="name">
            <el-input v-model="advert_link" style="width: 300px"></el-input>
          </el-form-item> -->

            <!--     <el-form-item label="广告备注：" prop="name">
            <el-input
              type="textarea"
              v-model="advert_remark"
              style="width: 300px"
            ></el-input>
          </el-form-item> -->

            <el-form-item>
              <el-button @click="onSubmit()" type="primary" style="width: 120px"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </frame>
  </div>
</template>

<script>
import frame from "../../public/Frame.vue";
export default {
  data() {
    return {
      top1: "10-2",
      top2: ["10"],
      flag: 1,
      merchantShow: true, //
      storeShow: true, //
      businessList: [{}], //商户列表
      business_id: "", //选中的商户ID
      storeList: [{}], //门店列表
      storeid: "", //选中的门店ID
      uploadingUrl: "", //图片上传路径
      advert_name: "", //		广告名称
      advert_position: "", //	广告位置
      advert_position_list: [], //广告位置列表
      value: "", //广告位置列表选中的值
      start_time: "", //		开始时间例2021-01-01
      end_time: "", //		结束时间
      is_online: "", //		1上线2不上线
      advert_img: "", //	广告图片
      advert_link: "", //广告链接
      advert_remark: "", //广告备注
      login_type: "", //1、超级管理员 2、普通管理员（超级管理员不受权限控制）3、商家管理员 登录后获取 需选择门店后传
      storeid: "", //	门店id

      imageUrl: "",
      advert_id: "", //广告id
    };
  },
  methods: {
    niuceshi() {
      console.log(this.advert_position);
    },
    //刷新页面按钮
    refresh() {
      location.reload();
    },

    //选中商户查询门店
    changeMerchant(business_id) {
      console.log(business_id);
      this.storeid = business_id;
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: business_id,
        })
        .then((res) => {
          console.log(res);
          this.storeList = res.data;
        });
    },

    //图片上传操作
    handleReset(name) {
      this.$refs[name].resetFields();
      console.log(this.imageUrl);
      this.imageUrl = "";
    },

    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.advert_img = res.data.src;
      console.log(456456456, this.imageUrl, this.advert_img);
    },

    beforeAvatarUpload(file) {
      if (
        file.type === "image/png" ||
        file.type === "image/gif" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg"
      ) {
        const isJPG = file.type;
      }
      const isJPG = file.type;
      console.log(789789789);
      // const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

    //表单提交
    onSubmit() {
      console.log(this);
      console.log(this.advert_id);
      // return false
      //广告添加接口
      if (this.advert_id == "") {
        this.$request
          .addAdvert({
            token: this.$storage.getLocal("token"), //登陆进来的tooken
            admin_type: this.$storage.getLocal("type"), //身份          advert_name: this.advert_name, //	string	广告名称
            advert_name: this.advert_name, //广告名
            // advert_position: this.advert_position, //	string	广告位置
            // start_time: this.start_time, //	string	开始时间例2021-01-01
            // end_time: this.end_time, //	string	结束时间
            // is_online: this.is_online, //	int	1上线2不上线
            advert_img: this.advert_img, //	string	广告图片
            // advert_link: this.advert_link, //	string	广告链接
            // advert_remark: this.advert_remark, //		string	广告备注
            store_id: this.storeid, //	int	门店id
          })
          .then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$router.push({ path: "advertisingList" });
            } else {
              alert("请求失败");
            }
          });
      } else {
        this.$request
          .updateAdvert({
            token: this.$storage.getLocal("token"), //登陆进来的tooken
            admin_type: this.$storage.getLocal("type"), //身份          advert_name: this.advert_name, //	string	广告名称
            advert_name: this.advert_name, //广告名
            advert_id: this.advert_id,
            // advert_position: this.advert_position, //	string	广告位置
            // start_time: this.start_time, //	string	开始时间例2021-01-01
            // end_time: this.end_time, //	string	结束时间
            // is_online: this.is_online, //	int	1上线2不上线
            advert_img: this.advert_img, //	string	广告图片
            // advert_link: this.advert_link, //	string	广告链接
            // advert_remark: this.advert_remark, //		string	广告备注
            store_id: this.storeid, //	int	门店id
            // advert_id: this.advert_id, //广告id
          })
          .then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$router.push({ path: "advertisingList" });
            } else {
              alert("请求失败");
            }
          });
      }
    },
  },

  created() {
    this.admin_type = this.$storage.getLocal("type");
    console.log(this.admin_type);
    if (this.admin_type == 1) {
      this.merchantShow = true;
      this.storeShow = true;
    }
    if (this.admin_type == 2) {
      console.log(22222222);
      // this.merchantShow = true;
      this.storeShow = true;
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          // business_id: business_id,
        })
        .then((res) => {
          console.log("帅气的我", res);
          // this.ruleForm.storeList = res.data;
          this.storeList = res.data;
        });
    }
    // 请求商户列表
    this.$request.businessList({}).then((res) => {
      console.log(res);
      this.businessList = res.data;
    });
    let admin_type = this.$storage.getLocal("type");
    if (admin_type == 2 || admin_type == 3) {
      this.merchantShow = false;
    }
    this.uploadingUrl = this.$request.imgUrl() + "PHPExcel/fileUpload";

    //请求广告位置接口
    this.$request.advertPosition({}).then((res) => {
      console.log(res);

      if (res.code == 0) {
        this.advert_position_list = res.data;
      } else {
        alert("广告位置接口没有请求到");
      }
    });

    //接受编辑的默认值
    // 走编辑
    let edit = this.$route.query;
    console.log("1111111111111111", edit);
    if (edit.id) {
      console.log("走吗？", edit.advert_img);
      this.top1 = "";
      // this.merchantShow = false
      this.advert_id = edit.id;
      this.advert_name = edit.advert_name;
      this.advert_position = edit.advert_position;
      this.start_time = edit.start_time;
      this.end_time = edit.end_time;
      this.is_online = edit.is_online;
      this.imageUrl = edit.advert_img;
      this.advert_link = edit.advert_link;
      this.advert_remark = edit.advert_remark;
      // let stroeid = edit.store_id
      // is_online.toString()
      // this.storeid =  parseInt(storeid) ;

      this.$request
        .getUpStoreData({
          token: this.$storage.getLocal("token"),
          storeid: edit.store_id,
        })
        .then((r) => {
          console.log(123, r);
          if (r.code == 0) {
            //   console.log(r);
            this.storeList = r.data.pstoreid;
            this.$request
              .getStoreDataList({
                token: this.$storage.getLocal("token"),
                pstoreid: r.data.pstoreid,
                login_type: this.$storage.getLocal("type"),
              })
              .then((r) => {
                console.log(1233333333333, r);
                if (r.code == 0) {
                  this.storeList = r.data;
                  this.storeid = parseInt(edit.store_id);

                  console.log(r);
                }
              });
          }
        });
    }
    console.log(this.storeid);
  },
  components: {
    frame,
  },
};
</script>

<style  scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 40px;
  height: 40px;
  background-color: rgb(243, 243, 243);
}

.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.center {
  /* height: 500px; */
  /* border: 1px solid red; */
}
/* 上传图片的样式 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.center {
  background-color: rgb(245, 247, 249);
  padding-top: 10px;
  padding-bottom: 25px;
  min-height: 650px;
}

.bck {
  width: 85%;
  background-color: white;
  /* margin-top: 20px; */
  /* border: 1px solid red; */
  margin-left: 14%;
  /* margin-top: 20px; */
  padding-top: 70px;
  padding-bottom: 50px;
  padding-left: 15%;
  padding-right: 350px;
  /* line-height: 20px; */
}
</style>